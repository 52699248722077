@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('label')
{
	@include type-1;
	padding: $rule * 0.1 $rule * 0.5;
	border-radius: $rule;
	background-color: $color06;

	@include modifier('disabled')
	{
		background-color: $color02;
	}

	@include modifier('success')
	{
		background-color: $color08;
	}
}