// colors

$color01: black;
$color02: #e7e7e7;
$color03: #f6f6f6;
$color04: white;
$color05: #e66b4f;
$color06: #f5d2ca;
$color07: lighten( #e66b4f, 35% );
$color08: #50e3c2;
$color09: #c0c0c0;
$color10: #666666;
$color11: #888888;

// breakpoints

$breakpoints: (
	650px,
	750px,
	1024px,
	1150px,
	1280px,
	1375px,
	1500px,
	1600px,
	1750px,
	1900px,
);

// distances, lengths, margins
$border-radius: 3px;
$border-width-01: 1px;
$border-width-02: 2px;
$border-width-03: 5px;
$border-width-04: 3px;
$rule: 2rem;

// fx
$box-shadow-01: 0 0 $rule * 0.5 rgba( $color01, .1 );
$box-shadow-02: 0 0 $rule * 0.5 rgba( $color01, .175 );