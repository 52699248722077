@import '../../../style/config';
@import '../../../style/bem';

@include block('slideshow')
{
	@include element('view')
	{
		height: 50vh;
		position: relative;
	}

	@include element('slide')
	{
		display: flex;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		transition: opacity 1.25s;

		@include modifier('active')
		{
			opacity: 1;
			z-index: 2;
		}

		@include modifier('prev')
		{
			opacity: 0;
			transition-delay: .25s;
		}
	}
}