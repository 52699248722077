@use "sass:math";

@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('notification-stack')
{
	position: fixed;
	z-index: 9999;
	bottom: $rule;
	right: $rule;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	pointer-events: none;

	@include element('item')
	{
		margin-top: math.div($rule, 3);
	}
}