@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('slide-out-panel')
{
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	right: -$rule;
	bottom: 0;
	width: 50%;
	overflow: auto;
	padding: $rule;
	padding-top: $rule * 1.5;
	box-shadow: $box-shadow-01;
	background-color: $color04;
	transform-origin: right center;
	transform: translateX( 100% );
	transition: transform .4s;

	@include element('header')
	{
		position: relative;
		margin-bottom: $rule * 2;
	}

	@include element('title')
	{
		@include type-9;
	}

	@include element('subtitle')
	{
		margin-top: $rule * 0.5;
	}

	@include element('close')
	{
		position: absolute;
		top: 0;
		right: 0;
	}

	@include element('content')
	{
		// for reference
	}

	@include element('footer')
	{
		margin-top: auto;
		display: flex;
		justify-content: flex-start;
	}

	@include modifier('open')
	{
		right: 0;
		transform: translateX( 0 );
	}
}