@import '../../../style/config';
@import '../../../style/typography';
@import '../../../style/bem';

@include block('form')
{
	@include element('input')
	{
		margin-bottom: $rule * 0.5;

		&:last-child
		{
			margin-bottom: 0;
		}
	}

	@include element('error-message')
	{
		display: inline-block;
		color: $color05;
		border: $border-width-02 solid $color05;
		border-radius: $border-radius;
		padding: $rule * 0.125 $rule * 0.25;
		margin-top: $rule * 0.25;
	}

	@include element('footer')
	{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-top: $rule * 1.5;

		a
		{
			@include type-1;
			color: $color05;
			border-bottom: $border-width-01 solid $color05;
			margin-left: $rule * 0.5;
		}
	}
}