// main sass file for project Morley PRO

@import 'style/reset';
@import 'style/util';
@import 'style/config';
@import 'style/bem';
@import 'style/typography';
@import 'style/wrap';

html
{
	overflow-y: scroll;
	overflow-x: hidden;
	font-size: 75%;

	@include breakpoint( 2 )
	{
		font-size: 80%;
	}

	@include breakpoint( 3 )
	{
		font-size: 85%;
	}

	@include breakpoint( 4 )
	{
		font-size: 85%;
	}

	@include breakpoint( 5 )
	{
		font-size: 90%;
	}

	@include breakpoint( 7 )
	{
		font-size: 95%;
	}

	@include breakpoint( 9 )
	{
		font-size: 100%;
	}

	@include breakpoint( 10 )
	{
		font-size: 105%;
	}
}

body
{
	@include type-5;
}