@import '../../../style/config';
@import '../../../style/bem';

@include block('input-stack')
{
	display: flex;
	justify-content: space-between;

	@include element('item')
	{
		width: 100%;

		&:nth-child(odd)
		{
			padding-right: $rule * 0.5;
		}

		&:nth-child(even)
		{
			padding-left: $rule * 0.5;
		}
	}
}