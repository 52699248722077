@import '../../../style/config';
@import '../../../style/bem';

@keyframes open-overlay
{
	from
	{
		opacity: 0;
	}
}

@include block('overlay')
{
	position: fixed;
	z-index: 3;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba( $color03, .75 );
	animation: open-overlay .75s;
	padding: $rule * 2;
}