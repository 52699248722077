@use "sass:math";

@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('product-item')
{
	display: flex;
	flex-direction: column;
	padding: 0 math.div($rule, 1.5);

	@include element('content')
	{
		position: relative;
	}

	@include element('title')
	{
		@include type-6;
		text-transform: lowercase;
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: math.div($rule, 3);
		cursor: pointer;
	}

	@include element('price')
	{
		@include type-6;
		text-transform: lowercase;
		display: block;
		width: 100%;
		text-align: center;
		cursor: pointer;
	}

	@include element('img')
	{
		position: relative;
		z-index: 1;
		display: block;
		cursor: pointer;

		img
		{
			display: block;
			width: 100%;
		}
	}

	@include element('variations')
	{
		position: absolute;
		z-index: 3;
		top: 0;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		@include type-10;
	}

	@include element('variations-item')
	{
		margin: math.div($rule, 13) 0;

		&:first-child { margin-top: 0 }
		&:last-child { margin-bottom: 0 }

		@include modifier('out-of-stock')
		{
			text-decoration: line-through;
		}
	}

	@include element('footer')
	{
		margin-top: auto;
		padding-top: $rule * 0.5;
	}

	@include element('variation-select')
	{
		float: left;
		width: 60%;
	}

	@include element('amount-input')
	{
		float: left;
		padding-left: math.div($rule, 3);
		width: 40%;
	}
}