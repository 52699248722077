@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@keyframes loader-spin
{
	to
	{
		transform: rotate( 360deg );
	}
}

@include block('loader')
{
	width: $rule * 0.5;
	height: $rule * 0.5;
	border: $border-width-02 solid $color01;
	border-left: $border-width-02 solid transparent;
	border-top: $border-width-02 solid transparent;
	border-radius: 50%;
	opacity: 0;
	transition: opacity .2s;
	animation: loader-spin .35s linear infinite;

	@include modifier('loading')
	{
		opacity: 1;
	}

	@include modifier('large')
	{
		width: $rule * 1.35;
		height: $rule * 1.35;
		border-width: $border-width-04;
	}
}