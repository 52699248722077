@import '../../../style/config';
@import '../../../style/bem';

@include block('table-row')
{
	transition: background-color .75s;

	@include element('data')
	{
		padding: $rule * 0.5 0;
		border-bottom: $border-width-01 solid $color02;

		@include block-modifier('highlight')
		{
			border-bottom-color: $color06;
		}
	}

	&:hover
	{
		background-color: $color03;
	}

	@include modifier('highlight')
	{
		background-color: $color07;

		&:hover
		{
			background-color: $color07;
		}
	}

	td:first-child
	{
		padding-left: $rule * 0.5;
	}

	td:last-child
	{
		padding-right: $rule * 0.5;
	}
}