@mixin global-modifier( $class_names... )
{
	@include parent-option( 'body', $class_names )
	{
		@content;
	}
}

@mixin parent-option( $selector, $class_names )
{
	@for $i from 1 through length( $class_names )
	{
		#{$selector}.#{nth( $class_names, $i )} &
		{
			@content;
		}
	}
}

@mixin clearfix // clearfix ( IE8 and above, which is fine! )
{
	&:after
	{
		content: '';
		display: table;
		clear: both;
	}
}

@mixin breakpoint( $i )
{
	@media screen and ( min-width: nth( $breakpoints, $i ) )
	{
		@content;
	}
}