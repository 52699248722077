@use "sass:math";

@import '../../../style/config';
@import '../../../style/typography';
@import '../../../style/util';
@import '../../../style/bem';

@include block('cart')
{
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	@include element('entry-list')
	{
		width: 100%;
		margin-bottom: $rule;
	}

	@include element('entry')
	{
		margin-bottom: math.div($rule, 1.5);

		&:last-child
		{
			margin-bottom: 0;
		}
	}

	@include element('total')
	{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include type-9;

		@include breakpoint( 2 )
		{
			width: 45%;
		}
	}

	@include element('total-title')
	{
		@include type-8;
		color: $color05;
	}
}