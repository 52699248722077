@import '../../../style/config';
@import '../../../style/bem';

@include block('sidebar')
{
	position: fixed;
	z-index: 2;
	top: 0;
	bottom: 0;
	left: 0;
	width: $rule * 8;
	background-color: $color04;
	border-right: $border-width-02 solid $color02;
	display: flex;
	flex-direction: column;

	@include element('logo')
	{
		cursor: pointer;
		padding: $rule;
		margin-bottom: $rule * 2;

		&:hover
		{
			opacity: .85;
		}
	}

	@include element('footer')
	{
		margin-top: auto;
	}
}