@use "sass:math";

@import '../../../style/config';
@import '../../../style/bem';

@include block('action-input')
{
	display: flex;
	align-items: center;

	@include element('input')
	{
		// for reference
	}

	@include element('button')
	{
		margin-left: math.div($rule, 1.75);
	}
}