@import '../../../style/config';
@import '../../../style/bem';

@include block('forgot-password')
{
	@include element('header')
	{
		display: flex;
		justify-content: center;
		padding: $rule 0;
		padding-bottom: $rule * 1.75;
	}

	@include element('content')
	{
		display: flex;
		width: 60%;
		margin: 0 auto;
		box-shadow: $box-shadow-01;
	}
}