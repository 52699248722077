@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('popout-menu-link')
{
	display: block;
	width: 100%;
	padding: $rule * 0.25 $rule * 0.5;
	cursor: pointer;
	color: $color01;
	@include type-1;
	border: none;
	border-bottom: $border-width-01 solid $color03;
	transition: background-color .75s, color .25s;

	&:last-child
	{
		border-bottom: 0;
	}

	&:hover
	{
		color: $color05;
		background-color: $color03;
	}
}