@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('sidebar-user')
{
	padding: $rule;

	@include element('name')
	{
		@include type-4;
	}

	@include element('store-name')
	{
		@include type-1;
	}

	@include element('email')
	{
		padding-top: $rule * 0.25;
		margin-top: $rule * 0.25;
		border-top: $border-width-01 solid $color02;
		@include type-1;
		color: $color05;
	}
}