@import '../../../style/config';
@import '../../../style/bem';

@include block('popout-menu')
{
	display: inline-block;
	cursor: pointer;
	position: relative;

	@include element('content')
	{
		display: none;
		position: absolute;
		z-index: 9999;
		top: 100%;
		width: $rule * 7;
		border-radius: $border-radius;
		box-shadow: $box-shadow-02;
		background-color: $color04;

		@include block-modifier('position-left')
		{
			left: 0;
		}

		@include block-modifier('position-right')
		{
			right: 0;
		}

		@include block-modifier('open')
		{
			display: block;
		}
	}
}