@import '../../../style/config';
@import '../../../style/bem';

@include block('loading-image')
{
	@include element('img')
	{
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
		opacity: 0;
		transition: opacity 2s;

		@include block-modifier('loaded')
		{
			opacity: 1;
		}
	}
}