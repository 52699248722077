@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('form-section')
{
	margin-bottom: $rule * 2;

	@include element('title')
	{
		@include type-8;
		margin-bottom: $rule * 1.5;
		color: $color05;
	}

	@include element('item')
	{
		width: 100%;
		margin-bottom: $rule * 0.5;

		&:last-child
		{
			margin-bottom: 0;
		}
	}
}