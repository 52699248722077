@mixin type-1
{
	font-family: Gudea, sans-serif;
	font-weight: 400;
	text-transform: none;
	font-size: 0.84rem;
	letter-spacing: 0;
}

@mixin type-2
{
	font-family: Gudea, sans-serif;
	font-weight: 700;
	text-transform: none;
	font-size: 0.96rem;
	letter-spacing: 0.21rem;
}

@mixin type-3
{
	font-family: Gudea, sans-serif;
	font-weight: 400;
	text-transform: uppercase;
	font-size: 0.85rem;
	letter-spacing: 0.1rem;
}

@mixin type-4
{
	font-family: Gudea, sans-serif;
	font-weight: 700;
	text-transform: none;
	font-size: 0.96rem;
	letter-spacing: 0;
}

@mixin type-5
{
	font-family: Gudea, sans-serif;
	font-weight: 400;
	text-transform: none;
	font-size: 0.96rem;
	letter-spacing: 0;
}

@mixin type-6
{
	font-family: Gudea, sans-serif;
	font-weight: 700;
	text-transform: none;
	font-size: 1.08rem;
	letter-spacing: 0.18rem;
}

@mixin type-7
{
	font-family: Gudea, sans-serif;
	font-weight: 400;
	text-transform: none;
	font-size: 1.08rem;
	letter-spacing: 0.18rem;
}

@mixin type-8
{
	font-family: Gudea, sans-serif;
	font-weight: 700;
	text-transform: none;
	font-size: 1.2rem;
	letter-spacing: 0;
}

@mixin type-9
{
	font-family: Gudea, sans-serif;
	font-weight: 700;
	text-transform: none;
	font-size: 2.16rem;
	letter-spacing: .7rem;
}

@mixin type-10
{
	font-family: Gudea, sans-serif;
	font-weight: 400;
	text-transform: uppercase;
	font-size: 0.75rem;
	letter-spacing: .02rem;
}
