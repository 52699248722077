@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('data-value')
{
	width: 100%;
	display: flex;
	padding: $rule * 0.5 0;
	border-bottom: $border-width-01 solid $color02;
	line-height: 1.85em;

	&:last-child
	{
		border-bottom: 0;
	}

	@include element('title')
	{
		@include type-4;
		width: 30%;
	}

	@include element( 'content' )
	{
		width: 70%;
	}
}