@use "sass:math";

@import '../../../style/config';
@import '../../../style/typography';
@import '../../../style/bem';

@include block('toolbar')
{
	display: flex;
	justify-content: flex-end;

	@include element('item')
	{
		@include type-1;
		padding-right: math.div($rule, 3);

		&:last-child
		{
			padding-right: 0;
		}
	}

	@include element('link')
	{
		cursor: pointer;
		color: $color05;
		border-bottom: $border-width-01 solid $color05;
	}
}