@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('content-pane')
{
	width: 100%;
	padding: $rule * 2;
	background-color: $color04;
	border-radius: 3px;

	@include element('header')
	{
		display: flex;
		justify-content: space-between;
		margin-bottom: $rule;
	}

	@include element('header-col')
	{
		display: flex;
		align-items: center;

		>*
		{
			margin-right: $rule * 0.5;

			&:last-child
			{
				margin-right: 0;
			}
		}
	}

	@include element('title')
	{
		@include type-9;
	}

	@include element('subtitle')
	{
		margin-bottom: $rule * 1.5;
	}

	@include element('content')
	{
		// for reference
	}
}