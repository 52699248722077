@use "sass:math";

@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('product-view')
{
	@include element('cols')
	{
		display: flex;
	}

	@include element('col')
	{
		width: 50%;
	}
	
	@include element('gallery')
	{
		img
		{
			display: block;
			width: 100%;
		}
	}

	@include element('content')
	{
		padding: 0 $rule;
	}

	@include element('title')
	{
		@include type-8;
		margin-bottom: math.div($rule, 3);
	}

	@include element('price')
	{
		@include type-8;
	}

	@include element('description')
	{
		margin-bottom: $rule;
	}

	@include element('header')
	{
		margin-bottom: $rule;
	}

	@include element('cart')
	{
		padding: $rule;
		box-shadow: $box-shadow-01;
	}

	@include element('cart-row')
	{
		display: flex;
		margin-bottom: $rule * 0.25;

		&:last-child
		{
			margin-bottom: 0;
		}
	}

	@include element('cart-select')
	{
		display: flex;
		align-items: center;
		width: 60%;
	}

	@include element('cart-action')
	{
		padding-left: $rule * 0.5;
		width: 40%;
	}
}