@use "sass:math";

@import '../../../style/config';
@import '../../../style/typography';
@import '../../../style/bem';

@include block('cart-entry')
{
	display: flex;
	justify-content: space-between;
	padding-bottom: $rule * 0.5;
	border-bottom: $border-width-02 solid $color02;

	@include modifier('compact')
	{
		padding-bottom: $rule * 0.25;
	}

	@include element('thumbnail')
	{
		width: $rule * 2.5;
		height: $rule * 2.5;

		@include block-modifier('compact')
		{
			width: $rule * 1.5;
			height: $rule * 1.5;
		}

		img
		{
			display: block;
			width: 100%;
		}
	}

	@include element('content')
	{
		flex-grow: 1;
		display: flex;
		justify-content: space-between;
		padding-left: $rule;

		@include block-modifier('compact')
		{
			align-items: center;
		}
	}

	@include element('info')
	{
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	@include element('title')
	{
		@include type-6;
		margin-bottom: math.div($rule, 3);

		@include block-modifier('compact')
		{
			margin-bottom: 0;
		}
	}

	@include element('details')
	{
		@include block-modifier('compact')
		{
			display: flex;
			align-items: center;
		}
	}

	@include element('price')
	{
		@include type-5;
		margin-right: math.div($rule, 1.5);
	}

	@include element('variation')
	{
		@include type-1;
	}

	@include element('actions')
	{
		display: flex;
		align-items: flex-start;

		@include block-modifier('compact')
		{
			align-items: center;
		}
	}

	@include element('action')
	{
		max-width: $rule * 2;
		margin-left: $rule * 0.5;

		&:first-child
		{
			margin-left: 0;
		}
	}
}