@use "sass:math";

@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('user-widget')
{
	@include type-2;
	display: flex;
	align-items: center;
	padding: math.div($rule, 6) $rule * 0.5;
	border-radius: $border-radius;
	background-color: $color04;
	box-shadow: $box-shadow-01;
	transition: color .25s, box-shadow .5s;

	&:hover
	{
		color: $color05;
		box-shadow: $box-shadow-02;
	}

	@include element('content')
	{
		display: flex;
		align-items: center;
		padding: 0 $rule 0 $rule * 0.5;
	}
}