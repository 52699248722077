@use "sass:math";

@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('wysiwyg')
{
	p, ul
	{
		margin-bottom: $rule * 0.5;

		&:last-child { margin-bottom: 0; }
	}

	ul
	{
		margin-left: $rule * 0.5;
	}

	ul li,
	ol li
	{
		padding-left: math.div($rule, 3);
		list-style: square;

		&:last-child
		{
			margin-bottom: 0;
		}
	}

	a
	{
		color: $color05;
		border-bottom: $border-width-01 solid $color05;
	}

	strong
	{
		font-weight: bold;
	}

	em
	{
		font-style: italic;
	}
}