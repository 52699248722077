@use "sass:math";

@import '../../../style/config';
@import '../../../style/typography';
@import '../../../style/bem';

@include block('logo')
{
	position: relative;
	width: $rule * 5;
	cursor: pointer;

	@include element('label')
	{
		position: absolute;
		right: math.div(-$rule, 1.5);
		top: -$rule * 0.5;
		padding: 0 math.div($rule, 6);
		@include type-1;
		font-size: .65rem;
		border-radius: $border-radius;
		background-color: $color06;
	}

	@include modifier('compact')
	{
		width: $rule * 4;
		font-size: .5rem;
	}
}