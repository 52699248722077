@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('category-nav')
{
	@include type-7;
	text-transform: lowercase;

	@include element('item')
	{
		margin-right: $rule;
		margin-bottom: $rule * 0.5;
		color: $color01;
		transition: color .5s;
		cursor: pointer;

		&:hover
		{
			color: $color05;
		}

		@include modifier('active')
		{
			color: $color01;
			border-bottom: $border-width-01 solid $color05;
			transition: none;

			&:hover
			{
				color: $color01;
			}
		}
	}
}