@use "sass:math";

@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('select-input')
{
	@include element('label')
	{
		display: block;
		width: 100%;
		margin-bottom: $rule * 0.25;
		@include type-4;
	}

	@include element('required-indicator')
	{
		@include type-3;
		display: inline-block;
		margin-left: $rule * 0.1;
		color: $color05;
	}

	@include element('input')
	{
		position: relative;
		display: block;
		width: 100%;
		height: $rule;
		line-height: $rule;
		padding: 0 math.div($rule, 3);
		padding-right: math.div($rule, 1.5);
		border: $border-width-01 solid $color02;
		border-radius: $border-radius;
		transition: border-color .2s;
		cursor: pointer;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: $color11;

		&:hover
		{
			color: $color01;
			border-color: $color05;
		}
	}

	@include element('input-arrow')
	{
		position: absolute;
		top: 0;
		right: 0;
		width: math.div($rule, 1.5);
		height: $rule;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: $color05;
	}

	@include element('option-list')
	{
		// for reference
	}

	@include element('option')
	{
		display: flex;
		align-items: center;
		padding: math.div($rule, 3) $rule * 0.5;
		border-bottom: $border-width-01 solid $color02;
		transition: background-color .35s;
		cursor: pointer;

		&:hover
		{
			background-color: $color03;
		}

		@include modifier('highlight')
		{
			background-color: $color07;
			border-bottom-color: $color06;

			@include element('option-icon')
			{
				color: $color05;
			}
		}
	}

	@include element('option-icon')
	{
		margin-right: math.div($rule, 3);
		color: $color09;
	}
}