@import '../../../style/config';
@import '../../../style/util';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('product-list')
{
	display: grid;
	grid-template-columns: repeat( 3, 33.33% );

	@include breakpoint( 6 )
	{
		grid-template-columns: repeat( 4, 25% );
	}

	@include element('item')
	{
		display: flex;
		border-right: $border-width-02 solid $color02;
		margin-bottom: $rule * 1.5;
	}
}