@use "sass:math";

@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('message')
{
	display: flex;
	padding: $rule * 0.5 $rule * 0.5;
	border: $border-width-02 solid $color05;
	border-radius: $border-radius;

	@include element('icon')
	{
		color: $color05;

		@include block-modifier('success')
		{
			color: $color08;
		}

		@include block-modifier('note')
		{
			color: $color09;
		}
	}

	@include element('text')
	{
		padding-left: math.div($rule, 3);
		@include type-4;
		color: $color05;

		@include block-modifier('success')
		{
			color: $color08;
		}

		@include block-modifier('note')
		{
			color: $color09;
		}
	}

	@include modifier('success')
	{
		color: $color08;
		border-color: $color08;
	}

	@include modifier('note')
	{
		color: $color09;
		border-color: $color09;
	}
}