@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@keyframes gender-list-content-box-fadein
{

	from
	{
		opacity: 0;
	}
}

@include block('gender-list')
{
	width: 100%;
	display: flex;
	flex-grow: 1;

	@include element('item')
	{
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		padding: 0 $rule * 2;
		padding-bottom: $rule * 4;

		&:first-child
		{
			padding-left: $rule * 4;
		}

		&:last-child
		{
			padding-right: $rule * 4;
		}
	}

	@include element('title')
	{
		text-align: center;
		@include type-9;
		margin-bottom: $rule;
	}

	@include element('subtitle')
	{
		text-align: center;
		@include type-8;
		margin-bottom: $rule;
	}

	@include element('content-box')
	{
		position: absolute;
		bottom: 25%;
		left: $rule * 1.5;
		padding: $rule;
		padding-bottom: $rule * 0.5;
		background-color: $color04;
		box-shadow: $box-shadow-01;
		animation: gender-list-content-box-fadein .75s;

		@include modifier('alternate')
		{
			bottom: initial;
			left: initial;
			top: 25%;
			right: $rule * 1.5;
		}
	}

	@include element('content-footer')
	{
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		@include modifier('alternate')
		{
			align-items: flex-start;
		}
	}

	@include element('footer-item')
	{
		margin-bottom: $rule * 0.5;
	}

	@include element('photo')
	{
		cursor: pointer;
		transition: transform .75s;

		&:hover
		{
			transform: scale( .95 );
		}
	}
}