@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('header')
{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: $rule $rule * 2;
	background-color: $color04;
	flex-shrink: 0;

	@include element('list')
	{
		display: flex;
	}

	@include element('list-item')
	{
		margin-right: $rule * 0.5;

		&:last-child
		{
			margin-right: 0;
		}
	}
}