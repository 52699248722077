@import '../../../style/config';
@import '../../../style/bem';

@include block('image-slideshow')
{

	@include element('thumbs')
	{
		margin-top: $rule * 0.5;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	@include element('thumb')
	{
		cursor: pointer;
		width: $rule * 2;
		margin: $rule * 0.2;
		opacity: .75;
		transition: opacity .25s;

		&:hover
		{
			opacity: 1;
		}
	}
}