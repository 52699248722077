@use "sass:math";

@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@keyframes notification-pop
{
	from
	{
		transform: translateY( $rule );
		opacity: 0;
	}
}

@keyframes notification-expire
{
	to
	{
		transform: translateY( math.div(-$rule, 3) );
		opacity: 0;
	}
}

@include block('notification')
{
	background-color: rgba( $color05, .85 );
	padding: math.div($rule, 3) $rule * 0.5;
	box-shadow: $box-shadow-02;
	border-radius: $border-radius;
	display: flex;
	align-items: center;
	animation: notification-pop .35s;

	@include element('icon')
	{
		color: $color04;
		margin-right: $rule * 0.5;
	}

	@include element('text')
	{
		@include type-5;
		color: $color04;
	}

	@include modifier('expired')
	{
		animation: notification-expire .25s;
		animation-fill-mode: both;
		pointer-events: none;
	}
}