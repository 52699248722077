@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('overlay-window')
{
	display: flex;
	flex-direction: column;
	max-height: 80vh;
	background-color: $color04;
	box-shadow: $box-shadow-01;
	border-radius: $border-radius;

	@include element('header')
	{
		display: flex;
		justify-content: center;
		padding: $rule * 0.5 $rule;
		@include type-8;
		color: $color05;
	}

	@include element('content')
	{
		overflow-y: auto;
	}
}