@import '../../../style/config';
@import '../../../style/typography';
@import '../../../style/util';
@import '../../../style/bem';

@include block('login')
{
	@include element('header')
	{
		display: flex;
		justify-content: center;
		padding: $rule 0;

		@include breakpoint( 2 )
		{
			padding: $rule * 2 0;
		}
	}

	@include element('content')
	{
		display: flex;
		align-items: flex-start;
	}

	@include element('photo')
	{
		width: 60%;
		margin-right: -10%;

		img
		{
			display: block;
			width: 100%;
		}
	}

	@include element('form')
	{
		position: relative;
		z-index: 100;
		width: 50%;
		margin-top: $rule * 3;
		box-shadow: $box-shadow-01;
	}
}