@use "sass:math";

@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('button')
{
	display: flex;
	align-items: center;
	padding: $rule * 0.25 $rule;
	border: $border-width-01 solid $color01;
	border-radius: $border-radius;
	cursor: pointer;
	@include type-4;
	letter-spacing: .2rem;
	text-transform: uppercase;

	&:hover
	{
		background-color: $color01;
		color: $color04;
	}

	@include element('icon')
	{
		margin-right: math.div($rule, 1.5);

		@include block-modifier('small')
		{
			margin-right: $rule * 0.25;
		}
	}

	@include modifier('large')
	{
		justify-content: space-between;
		padding: $rule * 0.5 $rule;
		width: 100%;
		border-radius: 0;
		background-color: $color01;
		color: $color02;
		@include type-2;
	}

	@include modifier('small')
	{
		@include type-1;
		padding: $rule * 0.125 math.div($rule, 3);
		border: $border-width-02 solid $color02;

		&:hover
		{
			border-color: $color01;
		}
	}

	@include modifier('active')
	{
		background-color: $color01;
		color: $color04;
		border-color: $color01;
	}
}