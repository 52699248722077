@use "sass:math";

@import '../../../style/config';
@import '../../../style/typography';
@import '../../../style/util';
@import '../../../style/bem';

@include block('confirm')
{
	padding: 0 $rule;
	max-width: 75vw;

	@include breakpoint( 2 )
	{
		max-width: 35vw;
	}

	@include element('text')
	{
		text-align: center;
		padding: $rule * 0.5 0;
	}

	@include element('footer')
	{
		display: flex;
		padding: math.div($rule, 3) 0 math.div($rule, 1.5);
		justify-content: center;
	}

	@include element('button')
	{
		margin: 0 math.div($rule, 6);
	}
}