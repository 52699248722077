@use "sass:math";

@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('gender-switch')
{
	display: flex;
	padding: 0 $rule $rule * 1.25 $rule;
	gap: $rule * 0.5;

	&:first-child {
		padding-bottom: $rule * 0.25;
	}

	@include element('item')
	{
		@include type-8;
		display: block;
		cursor: pointer;
		transition: color .25s;

		&:hover
		{
			color: $color10;
		}
		@include modifier('active')
		{
			&, &:hover,
			{
				color: $color05;
			}
		}
	}
}