@use "sass:math";

@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('text-input')
{
	@include element('label')
	{
		display: block;
		width: 100%;
		margin-bottom: $rule * 0.25;
		@include type-4;
	}

	@include element('required-indicator')
	{
		@include type-3;
		display: inline-block;
		margin-left: $rule * 0.1;
		color: $color05;
	}

	@include element('input')
	{
		display: block;
		width: 100%;
		height: $rule;
		line-height: $rule;
		padding: 0 math.div($rule, 3);
		border: $border-width-01 solid $color02;
		border-radius: $border-radius;
		transition: border-color .2s;
		color: $color11;

		&:focus
		{
			color: $color01;
			border-color: $color05;
		}

		&:disabled
		{
			background-color: transparent;
		}
	}
}