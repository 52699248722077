@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('widget')
{
	@include element('title')
	{
		@include type-8;
		color: $color05;
	}

	@include element('header')
	{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: $rule * 0.5 0;

		@include block-modifier('shadow')
		{
			margin: $rule * 0.5;
		}
	}

	@include element('footer')
	{
		margin: $rule * 0.5;
		margin-bottom: 0;
		display: flex;
		justify-content: flex-end;
	}

	@include modifier('shadow')
	{
		padding: $rule * 0.5;
		box-shadow: $box-shadow-01;
	}
}