@use "sass:math";

@import '../../../style/config';
@import '../../../style/typography';
@import '../../../style/bem';

@include block('table')
{
	table
	{
		width: 100%;
	}

	@include element('header')
	{
		text-align: left;
		padding: $rule * 0.5 0 math.div($rule, 1.25);
		@include type-3;

		&:first-child
		{
			padding-left: $rule * 0.5;
		}

		&:last-child
		{
			padding-right: $rule * 0.5;
		}
	}
}