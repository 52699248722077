@import '../../../style/config';
@import '../../../style/bem';
@import '../../../style/typography';

@include block('sidebar-item')
{
	width: 100%;
	position: relative;
	@include type-8;
	padding: $rule * 0.5 $rule;
	cursor: pointer;
	transition: color .25s;

	&:after
	{
		content: '';
		position: absolute;
		right: -$border-width-03 * 0.5 - 1;
		top: 0;
		bottom: 0;
		width: $border-width-03;
		background-color: transparent;
		transition: background-color .25s;
	}

	@include modifier('active')
	{
		color: $color05;

		&:after
		{
			background-color: $color05;
		}
	}
}