@import 'style/config';
@import 'style/typography';
@import 'style/bem';

@keyframes app-initialization
{
	from
	{
		opacity: 0;
	}
}

@include block('app')
{
	animation: app-initialization .75s;
}

@include block('app-loader')
{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $color03;
	display: flex;
	justify-content: center;
	align-items: center;
}