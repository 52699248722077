@use "sass:math";

@import '../../../style/config';
@import '../../../style/typography';
@import '../../../style/bem';

@include block('lookbook')
{
	display: flex;
	flex-wrap: wrap;
	margin-left: -$rule * 0.5;
	margin-right: -$rule * 0.5;

	@include element('photo')
	{
		display: flex;
		flex-direction: column;
		position: relative;
		width: 50%;
		height: auto;
		padding: 0 $rule * 0.5;

		&:nth-child( 3n + 3 )
		{
			margin: $rule 0;
			width: 100%;
		}

		&:last-child
		{
			margin-bottom: 0;
		}
	}

	@include element('img')
	{
		img
		{
			display: block;
		}
	}

	@include element('annotation')
	{
		@include type-1;
		margin-top: math.div($rule, 3);
		width: 100%;
		text-align: right;
	}

	@include element('annotation-link')
	{
		display: inline-block;
		transition: color .5s;
		color: $color11;
		cursor: pointer;

		&:after
		{
			display: inline-block;
			transition: color .5s;
			color: $color01;
			content: '—';
			padding: 0 $rule * 0.25;
		}

		&:last-child:after
		{
			display: none;
		}

		&:hover
		{
			color: $color05;
		}
	}
}