@use "sass:math";

@import '../../../style/config';
@import '../../../style/bem';

@include block('icon')
{
	// font styling for icon is below
	font-size: 1rem;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	letter-spacing: 0;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@include modifier('large')
	{
		svg {
			width: 1.75rem;
			height: 1.75rem;
		}
	}

	@include modifier('medium')
	{
		svg {
			width: 1.25rem;
			height: 1.25rem;
		}
	}

	@include modifier('extra-small')
	{
		svg {
			width: 0.75rem;
			height: 0.75rem;
		}
	}

	@include modifier('colored')
	{
		color: $color05;
	}

	@include modifier('background')
	{
		position: relative;

		&:after
		{
			content: '';
			position: absolute;
			z-index: -1;
			top: -.15em;
			right: -.15em;
			bottom: -.15em;
			left: -.15em;
			border-radius: 50%;
			background-color: $color06;
		}
	}

	@include modifier('highlight')
	{
		display: flex;
		justify-content: center;
		align-items: center;
		width: math.div($rule, 1.25);
		height: math.div($rule, 1.25);
		border-radius: 50%;
		background-color: $color06;
		font-size: 1rem;
		color: $color01;

		svg {
			width: 1rem;
			height: 1rem;
		}
	}
}