@import '../../../style/config';
@import '../../../style/bem';

@include block('user-view')
{
	@include element('content')
	{
		display: flex;
	}

	@include element('col')
	{
		width: 50%;

		&:first-child
		{
			width: 55%;
			padding-right: $rule;
		}

		&:last-child
		{
			padding-left: $rule;
			width: 45%;
		}
	}

	@include element('row')
	{
		margin-bottom: $rule;

		&:last-child
		{
			margin-bottom: 0;
		}
	}
}